.err404 {
    margin: 1rem 0 1.4rem 0;
    text-align: center;
    color: #1a237e;
    font-weight: bold;
    font-size:300px
}


@media only screen and (max-width: 800px) {
    .err404 {
            margin: 1rem 0 1.4rem 0;
            text-align: center;
            color: #1a237e;
            font-weight: bold;
            font-size: 150px
        }
}

