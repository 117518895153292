.clearBorder {
    border: none;
    padding-top: 3px;
 }
 .iconContainer {
     /* padding-top: 13px; */
     color: #154984;
 }
.headerStyle {
     color: #4e5055;
 }
.bodyStyle {
     color: #5d6267;
 }
.iconStyle{
    vertical-align: middle;
}
.displayRuby{
    display: ruby-base-container;
}
.sideMenuTitle{
    color:#039be5;
}
/* .headerTest {
    z-index: 2;
    position: inherit;
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: rgb(168, 21, 21);
} */