.fa {
  padding: 15px;
  font-size: 15px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  transition: 600ms;
  margin: 3px;
}

.la{
  padding: 15px;
  font-size: 15px;
  width: 50px;
  text-align: left;
  text-decoration: none;
  border-radius: 50%;
  transition: 600ms;
  margin: 3px;
}
.la-linkedin {
  background: none;
  color: white;
}


.fa:hover {
  opacity: 1;
  background: #212121;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-youtube {
  background: white;
  color: #f33939;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.fa-pinterest {
  background: #cb2027;
  color: gainsboro;
}

.fa-snapchat-ghost {
  background: #fffc00;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.fa-skype {
  background: #00aff0;
  color: white;
}

.fa-android {
  background: #a4c639;
  color: white;
}

.fa-dribbble {
  background: #ea4c89;
  color: white;
}

.fa-vimeo {
  background: #45bbff;
  color: white;
}

.fa-tumblr {
  background: #2c4762;
  color: white;
}

.fa-vine {
  background: #00b489;
  color: white;
}

.fa-foursquare {
  background: #45bbff;
  color: white;
}

.fa-stumbleupon {
  background: #eb4924;
  color: white;
}

.fa-flickr {
  background: #f40083;
  color: white;
}

.fa-yahoo {
  background: #430297;
  color: white;
}

.fa-soundcloud {
  background: #ff5500;
  color: white;
}

.fa-reddit {
  background: #ff5700;
  color: white;
}

.fa-rss {
  background: #ff6600;
  color: white;
}
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}
.overlay {
  position: relative;
  text-align: center;
  color: white;
}
.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.homepage-image1 {
  padding: 15px;
  border: 3px solid white;
  font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
}

.panel-column {
  float: left;
  width: 25%;
  padding: 0 5px;
}

.panel-row {
  margin: 0 -5px;
}

/* Clear floats after the columns */

/* Responsive columns */
@media screen and (max-width: 600px) {
  .panel-column {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
}

/* Style the counter cards */
.panel-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #444;
  color: white;
}

.fa-coffee {
  font-size: 50px;
}
.fa-paint-brush {
  font-size: 50px;
}
.fa-user {
  font-size: 50px;
}
.fa-chart-line {
  font-size: 50px;
}
.material-icons{
  display: inline-flex;
  vertical-align: top;
}

.certficates{
  width: 50px;
  height:50px;
  margin:10px;
  background-color: white;
}
